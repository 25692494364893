<template>
  <div>
    <a-modal :zIndex="1001"  title="新增展位" v-model:visible="addPopupIsShow"  @cancel="handleCancel" width="700px" :footer="null">
        <a-form ref="formRef" :model="formState" :rules="rules" :label-col="{span: 4}" :wrapper-col="{span: 18}">
          
            <a-form-item label="编号" name="title">
                <a-input v-model:value="formState.title" />
            </a-form-item>
            <a-form-item label="宽" name="width">
                <a-input v-model:value="formState.width" :disabled="isDisabled" />
            </a-form-item>
            <a-form-item label="高" name="height">
                <a-input v-model:value="formState.height" :disabled="isDisabled" />
            </a-form-item>
            <a-form-item ref="top" label="上边距" name="top">
                <a-input v-model:value="formState.top" :disabled="isDisabled" />
            </a-form-item>
            <a-form-item  label="左边距" name="left">
                <a-input v-model:value="formState.left" :disabled="isDisabled" />
            </a-form-item>
        </a-form>
        <div class="dialog-footer">
        
          <span class="wt" @click="isDisabled=!isDisabled" title="展位尺寸精度毫米级别调整">展位尺寸微调</span>
          <a-button type="primary" @click="sub()" :loading="confirmLoading"><PlusCircleOutlined v-if="!confirmLoading" />确定</a-button>
        
        </div>
    </a-modal>
  </div>
</template>
<script>
import { ref, reactive, defineComponent, onMounted,computed, watch, toRaw } from 'vue';
import {useStore} from 'vuex'
import {createRoom} from "@/api/index.js";
import {PlusCircleOutlined} from '@ant-design/icons-vue';
// import {message} from 'ant-design-vue';
import {stage} from './index.js'; 

export default defineComponent({
  components:{PlusCircleOutlined},
  setup(props,{emit}) {
    const store = useStore();
    const addPopupIsShow = computed(()=>store.state.addPopupIsShow);
    const addPopupData =computed(()=>store.state.addPopupData);
    const selectCategory = computed(()=>store.state.selectCategory);
    const selectGroup = computed(()=>store.state.selectGroup);

    const isDisabled = ref(true)

    const confirmLoading = ref(false);
    const formRef = ref();
    const formState = reactive({
      token:localStorage.getItem('mtoken'),
      category_id:selectCategory.value.id,
      parent_id:'0',
      title:'',
      width:'',
      height:'',
      top:'',
      left:'',
      status:'1'
    });

    const rules = {
       title: [{ required: true, message: '请输入展位编号', trigger: 'blur' }],
	      //  width: [{ required: true, message: '请输入展位宽', trigger: 'blur' }],
    };

   
    const handleCancel = ()=>{
      console.log("关闭弹出层")
      // formRef.value.resetFields();
      store.commit("setAddPopupIsShow",false);
    }


    //兼容弹出层状态，状态变化时候更新表单内容
    watch(addPopupIsShow, function(news,old){
      if(!news){
        return false;
      }

      console.log("selectCategory",selectCategory.value)
      console.log("store selectCategory", store.state.selectCategory)

      console.log('watch 弹出层状态发生变化');
      console.log(addPopupData.value);
      const rdata = addPopupData.value;
      formState.category_id = store.state.selectCategory.id;
      formState.width = rdata.width;
      formState.height = rdata.height;
      formState.left = rdata.left;
      formState.top = rdata.top;
    })

    const buyCheck = ()=>{
      return true;
    }

    const sub = ()=>{
      
      formRef.value
        .validate()
        .then(() => {
          confirmLoading.value=true;
          console.log('values', formState, toRaw(formState));
          createRoom(toRaw(formState)).then(res=>{
              store.dispatch("getLists",selectCategory.value.id);
              let sg = toRaw(selectGroup.value);
              console.log(sg);
              sg.remove();
          })
          setTimeout(()=>{
            confirmLoading.value=false;
            formRef.value.resetFields();
            store.commit("setAddPopupIsShow",false);
            store.commit("setTempListsRemove",store.state.addPopupData);
            stage.batchDraw();
          },1000)
        })
        .catch(error => {
          console.log('error', error);
        });
    }

   

    return {
      addPopupIsShow,
      confirmLoading,
      handleCancel,
      formRef,
      other: '',
      formState,
      rules,
      sub,
      isDisabled
    };
  },
});
</script>

<style scoped>
.dialog-footer{text-align: right;}
.dialog-footer button{margin-left:10px;}
.dialog-footer .wt{font-size:13px; margin-right:10px; color:#666; text-decoration: underline; cursor: pointer; user-select: none;}
</style>