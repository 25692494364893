// import { ref,watch,computed } from 'vue'
import { message } from 'ant-design-vue';
import {Stage} from 'konva';

import {drawGuid} from './guid.js';  //网格
import {drawRect} from './rect.js'; //矩形
import {drawTempRect} from './tempRect.js'; //未上传的矩形
import {drawRecting} from './draw.js'; //绘制中的矩形
import {drawPaddingRuler} from './paddingRuler.js'; //内边距标尺
import store from '@/store/';
// import {usekeyBoard} from '@/use/diy-use.js'

export let stage=null;
export let guidLayer = null;
export let rectLayer =null;
export let drawRectingLayer = null;
export let paddingRulerLayer =null;
// export let tempRectLayer = null;


export const drawCanvas = {
    init:(width=localStorage.getItem('canvasWidth'),height=localStorage.getItem('canvasHeight'))=>{
        // console.log(stage);
        if(stage){
            console.log("已经存在stage对象")
            // drawCanvas.reset();
            return false;
        }
        // let beforeWidth = localStorage.getItem('canvasWidth');
        // let beforeHeight = localStorage.getItem('canvasHeight');
        // if(beforeWidth!=width || beforeHeight!=height){
        //     console.log("窗口尺寸有更新！");
        //     console.log("更新stage")
        //     stage.setAttrs({ width: width, height: height});
        //     guidLayer.destroy();
        //     guidLayer = drawGuid(width,height);
        //     stage.add(guidLayer);
        //     stage.draw();
        // }
        console.log("canvas init 初始化");
        stage= new Stage({
            container: 'editCanvas', 
            width: width,
            height: height
        });

        
        drawTempRect.init(width,height,stage);
        guidLayer = drawGuid(width,height);
        rectLayer = drawRect(width,height,stage);
        drawRectingLayer = drawRecting(width,height,stage); //绘制中的矩形
        paddingRulerLayer = drawPaddingRuler(width,height);
        // tempRectLayer = drawTempRect.init(width,height,stage);  //未上传的矩形
        

        stage.add(guidLayer);
        stage.add(paddingRulerLayer);
        stage.add(rectLayer);
        stage.add(drawRectingLayer);
        
        // stage.add(tempRectLayer);
        localStorage.setItem("canvasWidth",width);
        localStorage.setItem("canvasHeight",height);

        return stage;
    },
    reset:(width=localStorage.getItem('canvasWidth')*1,height=localStorage.getItem('canvasHeight')*1)=>{
        
        console.log("更新stage")
        stage.setAttrs({ width: width, height: height});
        localStorage.setItem("canvasWidth",width);
        localStorage.setItem("canvasHeight",height);

        guidLayer.destroy();
        rectLayer.destroy();
        paddingRulerLayer.destroy();

        guidLayer = drawGuid(width,height);
        rectLayer = drawRect(width,height,stage);
        paddingRulerLayer = drawPaddingRuler(width,height);
       
        stage.add(guidLayer);
        stage.add(rectLayer);
        stage.add(paddingRulerLayer);

        //窗口有变化，清空临时展位
        drawTempRect.removeAll();
        store.commit("setTempListsRemoveAll",[]);
        message.warning('检测到浏览器窗口发生改变，临时展位将被清空！');
            
        stage.draw();
    },
    updateTempRect:(width=localStorage.getItem('canvasWidth'),height=localStorage.getItem('canvasHeight'))=>{
        if(!!rectLayer){
            rectLayer.destroy();
            rectLayer = drawRect(width,height,stage);
            stage.add(rectLayer);
            drawTempRect.update();
            store.commit("setDrawTempListsRemoveAll",[]);  //清空已经画过的临时展位
        }
    },
    removeTempRect:()=>{
        // tempRectLayer.destroyChildren();
        drawTempRect.removeAll();
        stage.draw();
    },
    updateGuid:(width=localStorage.getItem('canvasWidth'),height=localStorage.getItem('canvasHeight'))=>{
        guidLayer.destroy();
        guidLayer = drawGuid(width,height);
        stage.add(guidLayer);
    }
}


// export function drawCanvas(width=localStorage.getItem('canvasWidth'),height=localStorage.getItem('canvasHeight')) {

//     // const selectCategory = computed(() => store.state.selectCategory);
//     // const lists = computed(() => store.state.lists);
//     let beforeWidth = localStorage.getItem('canvasWidth');
//     let beforeHeight = localStorage.getItem('canvasHeight');
//     if(beforeWidth!=width || beforeHeight!=height){
//         console.log("窗口尺寸有更新！");
//         console.log("更新stage")
//         stage.setAttrs({ width: width, height: height});
//         guidLayer.destroy();
//         guidLayer = drawGuid(width,height);
//         stage.add(guidLayer);
//         stage.draw();
//     }

    

//     const tempList = computed(() => store.state.tempLists);

//     // stage && stage.destroy();
//     if(stage){
       
//     }else{
//         stage = new Stage({
//             container: 'editCanvas', 
//             width: width,
//             height: height
//         });
//         guidLayer = drawGuid(width,height);
//         rectLayer = drawRect(width,height,stage);
//         drawRectingLayer = drawRecting(width,height,stage); //绘制中的矩形
//         paddingRulerLayer = drawPaddingRuler(width,height);
//         tempRectLayer = drawTempRect(width,height,stage);  //未上传的矩形

//         stage.add(guidLayer);
//         stage.add(rectLayer);
//         stage.add(drawRectingLayer);
//         stage.add(paddingRulerLayer);
//         stage.add(tempRectLayer);
//     }


//     // if(guidLayer){
//     //     guidLayer.destroyChildren();
//     // }else{
        
//     // }


//     // guidLayer = drawGuid(width,height);
//     // let rectLayer = drawRect(width,height,stage); //服务器上的矩形
//     // let tempRectLayer = drawTempRect(width,height,stage);  //未上传的矩形
//     // let paddingRulerLayer = drawPaddingRuler(width,height);
//     // let drawRectingLayer = drawRecting(width,height,stage); //绘制中的矩形


//     // const selectCategory = computed(() => store.state.selectCategory);
//     // const radio = width/selectCategory.value.width;
//     // usekeyBoard(stage,radio);

    

//     // stage.add(guidLayer);
//     // stage.add(rectLayer);
//     // stage.add(tempRectLayer);
//     // stage.add(drawRectingLayer);
//     // stage.add(paddingRulerLayer);

//     // watch(tempList,function(newValue, oldValue){
//     //     console.log("templist变化")
//     //     // tempRectLayer && tempRectLayer.destroy();
//     //     tempRectLayer = drawTempRect(width,height,stage);
//     //     // stage.add(tempRectLayer);

//     //     // rectLayer && rectLayer.destroy();
//     //     rectLayer = drawRect(width,height,stage);
//     //     // stage.add(rectLayer);
//     //     stage.draw();
        
//     // })

//     // guidLayer.draw();
//     // rectLayer.draw();
//     // rectTempLayer.draw();
//     // paddingRulerLayer.draw();


//     // const loadImage = (url)=>{
//     //     return new Promise(function(resolve, reject) {
//     //         const img = new Image();
//     //         img.onload = function(){
//     //             resolve(img);
//     //         };
//     //         img.onerror = function() {
//     //             reject(new Error('Could not load image at ' + url));
//     //         };
//     //         img.src = url;
//     //     });
//     // }
//     // async function redraw(){
//     //     await loadImage(require('@/assets/icon/add.png'));
//     //     await loadImage(require('@/assets/icon/eyes.png'));
//     //     await loadImage(require('@/assets/icon/edit.png'));
//     //     rectLayer.draw();
//     //     stage.add(paddingRulerLayer);
//     // }
//     // redraw()

//     localStorage.setItem("canvasWidth",width);
//     localStorage.setItem("canvasHeight",height);
// }