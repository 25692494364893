import { ref, reactive, toRefs, computed} from 'vue'

import {Stage, Layer, Circle} from 'konva';

import {drawRect} from './rect.js'
import {drawPaddingRuler} from './paddingRuler.js'
import {drawDist} from './dist.js'

// import store from '@/store/';

export function drawCanvas(width,height) {

    // const selectCategory = computed(() => store.state.selectCategory);
    // const lists = computed(() => store.state.lists);

	let stage = new Stage({
            container: 'container', 
            width: width,
            height: height
    });

    let rectLayer = drawRect(width,height,stage);
    let paddingRulerLayer = drawPaddingRuler(width,height);
    let distLayer = drawDist(width,height);



    stage.add(rectLayer);
    stage.add(distLayer);
    stage.add(paddingRulerLayer);
   



    rectLayer.draw();
    paddingRulerLayer.draw();
    distLayer.draw();


    const loadImage = (url)=>{
        return new Promise(function(resolve, reject) {
            const img = new Image();
            img.onload = function(){
                resolve(img);
            };
            img.onerror = function() {
                reject(new Error('Could not load image at ' + url));
            };
            img.src = url;
        });
    }
    async function redraw(){
        await loadImage(require('@/assets/icon/add.png'));
        await loadImage(require('@/assets/icon/eyes.png'));
        await loadImage(require('@/assets/icon/edit.png'));
        rectLayer.draw();
        stage.add(paddingRulerLayer);
    }
    redraw()
}