<template>
  <teleport to="#teleport-target">
  <div class="loadingBox" :class="{show:visible}">
      <div class="wrap" v-if="defaultPercent>0">
          <!-- 进度
          <div>{{loadingSum}}</div>
          <div>{{loadingIndex}}</div> -->
          <a-progress type="circle" stroke-color="#1890ff" :percent="defaultPercent" />
      </div>
  </div>
  </teleport>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue'

const props = defineProps({
  msg: String,
  loadingSum:Number,
  loadingIndex:Number,
  visible:{type:Boolean,default:false}
})

const defaultPercent = computed(()=>Math.ceil(props.loadingIndex/props.loadingSum*100))

console.log(defaultPercent.value)

</script>

<style scoped>
.loadingBox{display:flex; position: fixed; z-index:9999; top:0;bottom:0; left:0; right:0; background:rgba(0,0,0,.0); visibility: hidden; transition-duration: 300ms;}
.loadingBox .wrap{margin:auto; color:#fff;}
.loadingBox.show{background:rgba(0,0,0,.6); visibility: visible;}

</style>

<style>
.loadingBox .ant-progress-circle .ant-progress-text{color:#fff;}
</style>