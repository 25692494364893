import {computed} from 'vue'
import store from '@/store/';

import {Layer, Line, Text} from 'konva';


//width为canvas在页面的显示宽度的像素
export function drawDist(width,height) {

    const selectCategory = computed(() => store.state.selectCategory);
    const lists = computed(() => store.state.lists);
    
    let layer = new Layer();
    const radio = width/selectCategory.value.width;

    // console.log("dist.....................")
    // console.log(selectCategory.value)
    // console.log(selectCategory.value.code)

    
    //根据展位内边距计算边距标尺
    // const top = selectCategory.value.top_padding*radio;
    // const bottom = selectCategory.value.bottom_padding*radio-2;
    // const left = selectCategory.value.left_padding*radio;
    // const right = selectCategory.value.right_padding*radio+1;
    // const vwidth = width - left -right;
    // const vheight = height -top -bottom;
    // const org = {x:0, y:0};

    // console.log(selectCategory.value.left_padding, left)

    let distW = [];
    let distH = [];
    let strokeWidth = 0.5;
    let fill = '#666';
    let stroke = '#666';
    let fontSize = 10;
    let offset = 850*radio;
    lists.value.map((item)=>{
        // console.log(item)
        let itemRight = item.left+item.width;
        let itemTop = item.top;
        let itemLeft = item.left;
        let itemBottom = itemTop+item.height;
        let minDist = null;
        let minHDist = null;
        lists.value.map((item2)=>{
            //检测水平位置展位间距
            if(Math.abs(itemTop-item2.top)<50 && item2.left >= itemRight){
                minDist === null && (minDist = item2.left - itemRight);
                if(item2.left - itemRight<minDist){
                    minDist = item2.left - itemRight;
                }
            }
            //检测垂直方向展位距离
            if(Math.abs(itemLeft-item2.left)<50 && item2.top >= itemBottom){
                minHDist === null && (minHDist = item2.top - itemBottom);
                
                if(item2.top - itemBottom<minHDist){
                    minHDist = item2.top - itemBottom;
                }
            }
        })

        if(minDist!==null&&minDist<12000&&minDist>2000){
            // distW.push({top:itemTop,left:itemRight,width:minDist});
            let rtop = Math.round(item.top*radio);
            let bool = distW.findIndex((item)=>{
                return item.left===itemRight && item.width === minDist &&  Math.abs(item.top - itemTop)<30000
            })
            if(rtop<height/2 &&item.title!=='A7-08'&&item.title!=='A10-06'&&item.title!=='A10-08'&&item.title!=='A9-52'&&item.title!=='A10-15'&&item.title!=='702'&&item.title!=='605'){
                bool===-1 &&distW.push({top:itemTop+item.height,left:itemRight,width:minDist});
            }
            if(rtop>height/2 && item.title!=='650' ){
                bool===-1 &&distW.push({top:itemTop,left:itemRight,width:minDist});
            }
        }
        //竖向标尺
        if(minHDist!==null&&minHDist<12000&&minHDist>2000){
            let bool = distH.findIndex((item)=>{
                return item.top===itemBottom && item.height === minHDist &&  Math.abs(item.left - itemLeft)<50000
            })
            if(item.title!=='623'){
                bool===-1 && distH.push({top:itemBottom,left:itemLeft,height:minHDist});
            }
            
        }
    })

    //手动创建间距
    if(selectCategory.value.code===1){
        // distW.push({top: 2400-800, left: 3050+22000, width: 5000});
    }
    if(selectCategory.value.code===3){
        distH.push({top: 0, left: 2500+15000, height: 3400});
        distH.push({top: 0, left: 2500+10000+800, height: 7400});
        distW.push({top: 32400+6000, left: 0, width: 4500});
        distW.push({top: 32400, left: 4500+12000, width: 3000});
        distW.push({top: 2400+58000+800, left: 2500+40000, width: 5000});
        distW.push({top: 2400+62000, left: 2500+40000, width: 6000});
    }
    if(selectCategory.value.code===4){
        distH.push({top: 0, left: 3370+48000, height: 2400});
        distH.push({top: 0, left: 3370+81000, height: 2400});
        distW.push({top: 2400+18000, left: 3370+76000, width: 5000});
        distW.push({top: 15400, left: 3370+74000, width: 7000});
    }
    if(selectCategory.value.code===6){
        // distH.push({top: 32500, left: 2100+118000, height: 6000});
        distH.push({top: 0, left: 2100+82000, height: 6500});
        distW.push({top: 4500+28000, left: 2100+16000, width: 3000});
        distW.push({top: 4500+28000, left: 2100+63000, width: 4000});
        distW.push({top: 4500+28000, left: 2100+93000, width: 3500});
    }
    if(selectCategory.value.code===7){
        distW.push({top: 2500+30000, left: 2100+3000, width: 4000});
        distW.push({top: 2500+30000, left: 2100+37000, width: 4000});

        distH.push({top: 0, left: 2100+7000, height: 5500});
    }
    if(selectCategory.value.code===9){
        distW.push({top: 2500+30000, left: 2000+112000, width: 4000});
        distW.push({top: 2500-2500, left: 2000+82000, width: 4000});
        // distW.push({top: 2500+30000, left: 2100+37000, width: 4000});
    }


    distH.map((item)=>{
        // console.log(item)
        let rleft = Math.round(item.left*radio);
        let rtop = Math.round(item.top*radio);
        let rheight = Math.round(item.height*radio);
        // let rwidth = Math.round(item.width*radio);
        if(checkCrossVsRect(item.left,item.top, item.left, item.top+item.height, lists, radio, height)){
            //画左侧标尺
            layer.add(new Line({
                // points: [0-offset,0,0-offset,0+rheight],
                points: [rleft-offset, rtop, rleft-offset, rtop+rheight],
                stroke,
                strokeWidth
            }));
            layer.add(new Line({
                points: [rleft-offset-4, rtop, rleft-4, rtop],
                stroke,
                strokeWidth
            }));
            layer.add(new Line({
                points: [rleft-offset-4, rtop+rheight, rleft-4, rtop+rheight],
                stroke,
                strokeWidth
            }));
            layer.add(new Text({
                x:rleft-offset-4, y:rtop,
                width:rheight,
                text:item.height,
                fill, fontSize, strokeWidth,
                align:'center',
                rotation:90
            }));
            layer.add(new Line({
                points: [rleft-offset-3,rtop-3, rleft-offset+3,rtop+3],
                stroke, strokeWidth
            }));
            layer.add(new Line({
                points: [rleft-offset-3,rtop+rheight-3, rleft-offset+3,rtop+rheight+3],
                stroke, strokeWidth
            }));
        }
    })

    distW.map((item)=>{
        let rleft = Math.round(item.left*radio);
        let rtop = Math.round(item.top*radio);
        // let rheight = Math.round(item.height*radio);
        let rheight = 0;
        let rwidth = Math.round(item.width*radio);

        //画下面侧标尺 ----上半部分展位
        if(rtop<height/2 && checkCross(item.left,item.top, item.left+item.width, item.top, distH)){
            layer.add(new Line({
                points: [rleft+0, rtop+rheight+offset, rleft+rwidth, rtop+rheight+offset],
                stroke,
                strokeWidth
            }));
            layer.add(new Line({
                points: [rleft+0, rtop+rheight+5, rleft+0, rtop+rheight+offset+5],
                stroke,
                strokeWidth
            }));
            layer.add(new Line({
                points: [rleft+rwidth, rtop+rheight+5, rleft+rwidth, rtop+rheight+offset+5],
                stroke,
                strokeWidth
            }));
            layer.add(new Text({
                x:rleft+0, y:rtop+rheight+offset+4,
                width:rwidth,
                text:item.width,
                fill, fontSize, strokeWidth,
                align:'center',
            }));
            layer.add(new Line({
                points: [rleft+3, rtop+rheight+offset-3, rleft-3, rtop+rheight+offset+3],
                stroke, strokeWidth
            }));
            layer.add(new Line({
                points: [rleft+rwidth+3, rtop+rheight+offset-3, rleft+rwidth-3, rtop+rheight+offset+3],
                stroke, strokeWidth
            }));
        }

        //画上面侧标尺 ----下半部分展位
        if(rtop>height/2 && checkCross(item.left,item.top, item.left+item.width, item.top, distH)){
            layer.add(new Line({
                points: [rleft+0, rtop-offset, rleft+rwidth, rtop-offset],
                stroke,
                strokeWidth
            }));
            layer.add(new Line({
                points: [rleft+0, rtop-5, rleft+0, rtop-offset-5],
                stroke,
                strokeWidth
            }));
            layer.add(new Line({
                points: [rleft+rwidth,rtop-5, rleft+rwidth, rtop-offset-5],
                stroke,
                strokeWidth
            }));
            layer.add(new Text({
                x:rleft+0, y:rtop-offset-fontSize-4,
                width:rwidth,
                text:item.width,
                fill, fontSize, strokeWidth,
                align:'center',
            }));
            layer.add(new Line({
                points: [rleft+3, rtop-offset-3, rleft-3, rtop-offset+3],
                stroke, strokeWidth
            }));
            layer.add(new Line({
                points: [rleft+rwidth+3, rtop-offset-3, rleft+rwidth-3, rtop-offset+3],
                stroke, strokeWidth
            }));
        }
    })

    return layer
}

function isIntersect(lineSegment1StartX, lineSegment1StartY, lineSegment1EndX, lineSegment1EndY, lineSegment2StartX, lineSegment2StartY, lineSegment2EndX, lineSegment2EndY) {
    // 判断第一条线段的起点和终点是否在第二条线段内部或重合
    if (isPointInsideLineSegment(lineSegment2StartX, lineSegment2StartY, lineSegment1StartX, lineSegment1StartY, lineSegment1EndX, lineSegment1EndY)) return true;
    if (isPointInsideLineSegment(lineSegment2EndX, lineSegment2EndY, lineSegment1StartX, lineSegment1StartY, lineSegment1EndX, lineSegment1EndY)) return true;
    // 判断第二条线段的起点和终点是否在第一条线段内部或重合
    if (isPointInsideLineSegment(lineSegment1StartX, lineSegment1StartY, lineSegment2StartX, lineSegment2StartY, lineSegment2EndX, lineSegment2EndY)) return true;
    if (isPointInsideLineSegment(lineSegment1EndX, lineSegment1EndY, lineSegment2StartX, lineSegment2StartY, lineSegment2EndX, lineSegment2EndY)) return true;
    // 若以上两种情况都未发生，则返回false表示两条线段不相交
    return false;
}
 
// 判断一个点是否在直线段内部或重合
function isPointInsideLineSegment(pointX, pointY, segmentStartX, segmentStartY, segmentEndX, segmentEndY) {
    var dx = segmentEndX - segmentStartX;
    var dy = segmentEndY - segmentStartY;
    var t = ((pointX - segmentStartX) * dx + (pointY - segmentStartY) * dy) / (dx * dx + dy * dy);
  
    if (t < 0 || t > 1) {
        return false;
    } else {
        var x = segmentStartX + t * dx;
        var y = segmentStartY + t * dy;
      
        return Math.abs(x - pointX) <= Number.EPSILON && Math.abs(y - pointY) <= Number.EPSILON;
    }
}

//横向标尺和纵向标尺检测
function checkCross(x, y, x1, y1, lists){
    let bool = true;
    for(let i=0; i<lists.length; i++){
      if(isIntersect(x, y, x1, y1, lists[i].left, lists[i].top, lists[i].left, lists[i].top+lists[i].height)){
        // console.log("交叉")
        bool = false;
        break;
      }
    }
    // console.log(bool);
    return bool;
}
// 纵向标尺和矩形标尺检测是否有交叉，纵向标尺，此处和矩形横向线段检测
function checkCrossVsRect(x, y, x1, y1, lists, radio, height){
    let bool = true;
    // console.log(lists)
    lists.value.map((item)=>{
        let rtop = Math.round(item.top*radio);
        if(rtop<height/2){
            //上半部分展位，标尺在下方
            if(isIntersect(x-800, y, x1-800, y1, item.left, item.top+item.height+0, item.left+item.width, item.top+item.height+0)){
                bool = false;
                console.log("上交叉")
            }
        }
        if(rtop>height/2){
            // console.log(x, y, x1, y1, item.left, item.top-800, item.left+item.width, item.top-800)
            //下半部分展位，标尺在上方
            if(isIntersect(x-800, y, x1-800, y1, item.left, item.top-0, item.left+item.width, item.top-0)){
                bool = false;
                console.log("下交叉")
            }
        }
        
    })
    return bool;
}

// var result = isIntersect(0, 0, 5, 5, 6, 7, 8, 9);
// console.log("结果：" + result);


