<template>
  <div>
    <mHeader @change="onSelectChange"></mHeader>
    <div class="content">
      <div class="headbox">
	      <div class="tips">
	        <div class="normal">未出售</div>
	        <div class="lock">锁定中</div>
	        <div class="finish">已销售</div>
           <div class="intro">
              <div>展馆总面积：<span>{{area}}m²</span></div>
              <div>展位数量：<span>{{zwLength}}个</span></div>
              <div>展位总面积：<span>{{zwArea.toFixed(0)}}m²</span></div>
           </div>
	      </div>
         
	      <div class="toolbox">
	        <div class="edit" @click="openEdit" v-if="isAdmin">DIY展位</div>
	        <div class="edit" @click="openExport">导出</div>
           <div class="switchbtn">
            <span style="margin-right:4px;">标尺</span>
            <a-switch 
               size="small"
               @change="rulerSwitch" 
               :checked="switchValue" />
            </div>
            <div class="switchbtn">
               <span style="margin-right:4px;">全屏</span>
               <a-switch 
                  size="small" 
                  @change="fullScreenSwitch" 
                  :checked="fullScreen" />
            </div>
	      </div>
	   </div>
      <hallbox></hallbox>
      <buyrect @exportBooth="exportBooth"></buyrect><!--购买锁定编辑展位-->
      
      <!--画展位-->
      <PopupEdit v-model:editVisible="editVisible" ></PopupEdit>
      <!--上传展位-->
      <addRect></addRect>
      <!--裁剪展位-->
      <cropRect></cropRect>
      
      <!--导出展馆平面图-->
      <PopupExport v-model:exportVisible="exportVisible"></PopupExport>

      <!--导出单个展位-->
      <BoothExport v-model:exportVisible="boothExportVisible"></BoothExport>

      <Shuiyin></Shuiyin>
      

    </div>
    <footer>
      <div>Uniceramics Expo <a data-v-06aeb1ee="" target="_bland" href="https://beian.miit.gov.cn/">粤ICP备20006720号</a><span data-v-06aeb1ee="">佛山陶联科技发展有限公司</span></div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, reactive, computed, watch, toRaw} from 'vue'
import {useStore} from 'vuex'
// import {useRouter} from 'vue-router'
// import {useGetMapStyle} from '@/use/diy-use.js'

import {useRouter} from 'vue-router'

import mHeader from "@/components/mHeader.vue";
import hallbox from "./components/hallbox.vue";
import buyrect from "./components/buyrect.vue";

import PopupEdit from "./popupEdit/popupEdit.vue";  //diy展位
import addRect from "./popupEdit/addRect.vue";  //新增展位
import cropRect from "./popupEdit/cropRect.vue";  //裁剪展位

import PopupExport from "./popupExport/popupExport.vue";  //导出展馆平面图
import BoothExport from "./boothExport/boothExport.vue";  //导出单个展位图

import Shuiyin from "@/components/Shuiyin.vue";




export default {
   name: 'Vue',
   components:{mHeader,hallbox,buyrect,PopupExport,PopupEdit,addRect,cropRect,BoothExport,Shuiyin},
   // components:{mHeader,hallbox,buyrect,PopupExport},
   setup(){
      const isAdmin = ref(false);
      const switchValue = ref(true);
      const store = useStore();
      const lists = computed(() => store.state.lists);
      const selectCategory = computed(() => store.state.selectCategory);
      // const rulerIsShow = computed(() => store.state.rulerIsShow);
      const editVisible =ref(false);
      const exportVisible =ref(false);
      const boothExportVisible = ref(false)

      const fullScreen = ref(false)

      const area = ref(0); //展馆总面积
      const zwArea = ref(0);  //展位总面积
      const zwLength = ref(0); //展馆个数

      if(store.state.user.role_name==="管理员"){
         isAdmin.value = true;
      }

      const router = useRouter();
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if(isMobile){
         router.push({ path: '/mobile' });
      }
   
      watch(lists, (newValue, oldValue) => {
         zwArea.value = 0;
         zwLength.value = lists.value.length;
         area.value = Math.floor(selectCategory.value.width*selectCategory.value.height/1000000)
         lists.value.map((rect)=>{
            zwArea.value+=(rect.width*rect.height)/1000000
         })
      })

      const onSelectChange = (id)=>{
         store.dispatch("getLists",id);
      }

      const openEdit = ()=>{
         editVisible.value=true;
      }
      const openExport = ()=>{
         exportVisible.value=true;
      }

      const rulerSwitch = ()=>{
         switchValue.value = switchValue.value?false:true;
         store.commit("setRulerIsShow",switchValue.value);
      }

      const fullScreenSwitch = ()=>{
         fullScreen.value = fullScreen.value?false:true;
         console.log(fullScreen.value)
         let element = document.documentElement
         if(!fullScreen.value){
            if (document.exitFullscreen) {
            document.exitFullscreen()
            } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
            } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
            } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
            }
         }else{
            if (element.requestFullscreen) {
               element.requestFullscreen()
            } else if (element.webkitRequestFullScreen) {
               element.webkitRequestFullScreen()
            } else if (element.mozRequestFullScreen) {
               element.mozRequestFullScreen()
            } else if (element.msRequestFullscreen) { // IE11
               element.msRequestFullscreen()
            }
         }
      }

      const exportBooth = (booth)=>{
         console.log(booth)
         boothExportVisible.value = true;

      }

      return {
         lists,
         isAdmin,
         switchValue,
         rulerSwitch,
         onSelectChange,
         openEdit,
         openExport,
         exportVisible,
         editVisible,
         zwLength,
         area,
         zwArea,
         fullScreen,
         fullScreenSwitch,
         boothExportVisible,
         exportBooth
      }
   }
}
</script>

<style scoped>
.content{display: flex; min-height:calc(100vh - 100px); flex-direction: column; justify-content: center; align-items: center;}
/* footer{ height:60px; background: url(../../assets/footer2.jpg) center no-repeat; background-size:100% 100%;} */
footer{height:40px; box-sizing: content-box; text-align: center; color:#666; background: linear-gradient(to bottom, #f1f1f1,#f1f1f1);}
footer>div{padding-top:18px;}
footer>div a{color:#00367a;}

.headbox{width:95%; display: flex; justify-content: space-between;}
.headbox .tips{margin-bottom:10px; margin-top:20px; display:flex;}
.headbox .tips>div{padding-left:30px; position: relative; font-size:13px; line-height:14px; margin:0 5px; }
.headbox .tips>div:before{content:''; display:block; width:25px; height:12px; border:1px solid #ddd; position: absolute; top:0px; left:0; border-radius: 4px;}
.headbox .tips>div.finish:before{background:#ffadad;}
.headbox .tips>div.lock:before{background:#ddd; }
.headbox .tips>div.choice:before{background:rgba(144,238,144,0.43);}
.headbox .tips>div.normal:before{border:1px solid #ccc;}

.headbox .tips>div.intro{padding-left:20px;}
.headbox .tips>div.intro:before{display: none;}
.headbox .tips>div.intro>div{display:inline; margin-right:10px;}
.headbox .tips>div.intro>div>span{color:red; font-weight: bold;}

.toolbox{display:flex; align-items: center;}
.toolbox .tool{width:20px; height:20px; line-height:20px; margin-left:4px; border:1px dashed #666; text-align: center; cursor:pointer; font-size:15px;}
.toolbox .tool.che{background:rgba(144,238,144,0.43);}
.toolbox .edit{color:#409EFF; margin-right:10px; cursor:pointer; font-size:12px; font-weight: bold; padding:3px 6px; border:1px solid #ccc; border-radius:5px; background:#fff; transition-duration: 300ms;}
.toolbox .edit:hover{background:#eee;}
.toolbox .edit:nth-of-type(1){color:red;}
.toolbox .switchbtn{padding:3px 6px;}

</style>