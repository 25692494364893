import { ref, reactive, toRefs, computed} from 'vue'

import {Stage, Layer, Group, Text} from 'konva';
import {drawRuler} from './ruler.js'
import store from '@/store/';

export function drawRect(width,height) {

    //表单状态
    const isAdmin = computed(()=>(store.state.user.role_id===1||store.state.user.role_id===2));
    // const isSell = computed(()=>store.state.user.role_id===3);
    // const isCaiwu = computed(()=>store.state.user.role_id===2);
    const rulerIsShow = computed(() => store.state.rulerIsShow);
    const selectCategory = computed(() => store.state.selectCategory);
    const lists = computed(() => store.state.lists);
    const layer = new Layer();
    const radio = width/selectCategory.value.width;

    var tween;

    lists.value.map((rect)=>{
        // console.log(rect)
        //矩形在页面显示的宽高
        const vWidth = rect.width*radio;
        const vHeight = rect.height*radio;
        let icon;
        //创建一个分组
        let group = new Group({
            x: rect.left*radio+0.5,
            y: rect.top*radio+0.5,
            width: vWidth,
            height: vHeight,

            // draggable: true,
            // dragBoundFunc:(pos)=>{
            //     let x = pos.x;
            //     let y = pos.y;
            //     if(x<=0){
            //         x =0;
            //     }
            //     if(x>=width-group.attrs.width){
            //         x =width-group.attrs.width;
            //     }
            //     if(y<=0){
            //         y =0;
            //     }
            //     if(y>=height-group.attrs.height){
            //         y =height-group.attrs.height;
            //     }
            //     return {
            //         x:x,
            //         y:y
            //     }
            // }
            
        });
        group.on('mouseover', function () {
            if (tween) {tween.destroy();}
            const selectIcon= layer.find("#icon"+rect.id)[0];
            tween = new Konva.Tween({
                node: selectIcon,
                duration: 0.3,
                opacity:0.7,
            }).play();
            // selectIcon.opacity(0.7);
            // layer.draw();
        });
        group.on('mouseout', function (e) {
            if (tween) {tween.destroy();}
            const selectIcon= layer.find("#icon"+rect.id)[0];
            selectIcon.opacity(0.1);
            layer.draw();
        });
        // group.on('dragmove',(e)=>{
        //     let x = e.target.attrs.x;
        //     let y = e.target.attrs.y;
        //     if(x<=0){
        //         return false;
        //     }
        // })

        const {status, title } = rect;
        let fillStyle = '#fff';
        if(status===2){
            fillStyle="#ffadad";
        }else if(status===3){
            fillStyle="#ddd";
        }else if(status===4){
            fillStyle="#bcffbc";
        }

        //画矩形
        var box = new Konva.Rect({
            x: 0, y: 0,
            width: rect.width*radio,
            height: rect.height*radio,
            fill: fillStyle,
            stroke: '#ed1f24',
            strokeWidth: 1.5
        });
        group.add(box);


        let offset = 10;
        let companyText,titleText,areaText,minTitleText;
        let fontSize = rect.width>6000 ? 20 :10;
        // 画参展品牌
        // if(isAdmin.value&&rect.status &&!!rect.order_form.customer_brand){
        if(rect.status &&!!rect.order_form.customer_brand){
            offset = 20;
            companyText = new Text({
                x:0, y:vHeight/2,
                width: vWidth,
                text:rect.order_form.customer_brand.substr(0,8),
                fill:'#666',fontSize:14, wrap:"none", ellipsis:true,
                align:'center', verticalAlign:'middle'
            })
             group.add(companyText);
        }
        // console.log("画布高度：",height)
        //根据浏览器高度，动态设置标题字体大小
        let titleFontSize = 20;
        if(height<541){
            titleFontSize = height/30
        }
        // 画展位号+公司名
        titleText = new Text({
            x:0, y:vHeight/2-offset-5,
            width: vWidth,
            text:title,
            fill:'#333', fontSize:titleFontSize, fontStyle:'bold',
            align:'center', verticalAlign:'middle'
        })
        // 画尺寸
        areaText = new Text({
            x:0, y:vHeight/2+offset+2,
            width: vWidth,
            // text:`${Math.floor(rect.width/1000)}×${Math.floor(rect.height/1000)}=${Math.floor(rect.width*rect.height/1000000)}`,
            text:`${rect.width/1000}×${rect.height/1000}=${rect.width*rect.height/1000000}`,
            fill:'#555',fontSize:12,
            align:'center', verticalAlign:'middle'
        })
        //展位高度小于6000
        if(rect.height<=6000){
            titleText.y(vHeight/2-5).fontSize(10)
            companyText && companyText.y(vHeight/2+5).fontSize(10)
            // companyText && companyText.visible(false);
            areaText.visible(false);
        }
        if(rect.width<=6000){
            titleText.y(vHeight/2-5).fontSize(10)
            companyText && companyText.y(vHeight/2+5).fontSize(10)
            // companyText && companyText.visible(false);
            areaText.visible(false);
        }
        if(rect.width<=6000&&rect.width<=6000){
            companyText && companyText.visible(false);
        }

        if(rect.width<=4000&&rect.width/rect.height>=2){
            titleText.y(vHeight-15).fontSize(10)
        }

        //展位高度小于6000
        if(rect.width<=4000||rect.height<=5000){
            titleText.visible(false);
            // 小面积展位画展位号
            minTitleText = new Text({
                x:5, y:5,
                width: vWidth,
                text:title,
                fill:'#333', fontSize:9, fontStyle:'bold',
                align:'left', verticalAlign:'middle'
            })
            group.add(minTitleText);
        }
        
        

        group.add(titleText);
        group.add(areaText);
        //画展位标尺
        if(selectCategory.value.code!==11&&selectCategory.value.code!==12){
            rulerIsShow.value && drawRuler(rect,width,height,group);
        }


        //画操作相关功能，如查看详情，购买展位等
        let imageObj = new Image();
        imageObj.onload = function() {
            icon = new Konva.Image({
                x: rect.width*radio-28,
                y: 5,
                image: imageObj,
                width: 20,
                height: 20,
                opacity:0.1,
                name:'icon'+rect.status,
                id:"icon"+rect.id,
                type:rect.status
            });
            if(rect.height<=4000){
                icon.setAttrs({x:(vWidth-20)/2,y:(vHeight-20)/2})
            }
           
            if(rect.height===5000){
                //展位高度等于5米的，logo和展位编号垂直居中
                icon.setAttrs({x:(vWidth-20)/2,y:(vHeight-40)/2})
                titleText.setAttrs({y:(vHeight-40)/2+23})
            }
            if(rect.height<=6000&&rect.width<=4000){
                icon.setAttrs({x:(vWidth-15)/2,y:(vHeight-20),width:15,height:15})
            }
            if(rect.height>6000&&rect.width<=4000){
                icon.setAttrs({x:(vWidth-15)/2,y:(vHeight-15)/2,width:15,height:15})
            }
            // if(rect.height<=4000&&rect.width<=4000){
            //     icon.setAttrs({x:(vWidth-15)/2,y:(vHeight-20),width:15,height:15})
            // }

            if(rect.status===1){
                group.add(icon);
            }
            if(isAdmin.value&&(rect.status===2||rect.status===3)){
                group.add(icon);
            }

            if(store.state.user.id === rect.member.id){
                group.add(icon);
            }

            
            

            icon.on('click' , (e)=>{
                store.commit("setBuyPopupIsShow",true);
                store.commit("setBuyPopupData",rect);
                console.log(rect)
            })

            icon.on('mouseover', function () {
                document.body.style.cursor = 'pointer';
            });
            icon.on('mouseout', function () {
                document.body.style.cursor = 'default';
            });
        };
        if(rect.status===1){
            imageObj.src = require('@/assets/icon/add.png');
        }
        if(rect.status===2){
            imageObj.src = require('@/assets/icon/eyes.png');
        }
        if(rect.status===3){
            imageObj.src = require('@/assets/icon/edit.png');
        }

        
        layer.add(group);
    })

    return layer
}