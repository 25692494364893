import { createVNode, computed} from 'vue'

import {Stage, Layer, Group, Text} from 'konva';
import {drawRuler} from './ruler.js'
import {usekeyBoard, useSetDragAlign} from '@/use/diy-use.js'
import store from '@/store/';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';

import {removeRoom} from "@/api/index.js";



export function drawRect(width,height,stage) {

    const rulerIsShow = computed(() => store.state.rulerIsShow);
    const selectCategory = computed(() => store.state.selectCategory);
    const lists = computed(() => store.state.lists);
    const layer = new Layer();
    const radio = width/selectCategory.value.width;
    const guidWidth = store.state.guidWidth;
    let selectGrout = null;

    var tween;

    stage.find('.cloudrect').each((group)=>{
        group.destroy();
        stage.batchDraw();
    })

    lists.value.map((rect)=>{
        // console.log(rect)
        //矩形在页面显示的宽高
        const vWidth = rect.width*radio;
        const vHeight = rect.height*radio;
        
        //创建一个分组
        let group = new Group({
            x: rect.left*radio+0.5,
            y: rect.top*radio+0.5,
            width: vWidth,
            height: vHeight,
            type:'status'+rect.status,
            name:"cloudrect",
            dragBoundFunc:(pos)=>{
                let x = pos.x;
                let y = pos.y;
                if(x<=0){
                    x =0;
                }
                if(x>=width-group.attrs.width){
                    x =width-group.attrs.width;
                }
                if(y<=0){
                    y =0;
                }
                if(y>=height-group.attrs.height){
                    y =height-group.attrs.height;
                }
                return {
                    x:x,
                    y:y
                }
            }
        });


        group.on('mousedown', function () {
            selectGrout = group;
            store.commit("setSelectGroup",selectGrout);
        });
        group.on('mouseover', function () {
            // document.body.style.cursor = 'move';
            if (tween) {tween.destroy();}
            const selectIcon= layer.find("#icon"+rect.id)[0];
            if(selectIcon){
                tween = new Konva.Tween({
                    node: selectIcon,
                    duration: 0.3,
                    opacity:0.7,
                }).play();
            }
        });
        group.on('mouseout', function () {
            document.body.style.cursor = 'default';
            if (tween) {tween.destroy();}
            const selectIcon= layer.find("#icon"+rect.id)[0];
            if(selectIcon){
                selectIcon.opacity(0.1);
                layer.draw();
            }
        });
        group.on('dragend', function(e) {
            useSetDragAlign(e.target, radio);
        });
        group.on('dragmove',(e)=>{
            let x = e.target.attrs.x;
            let y = e.target.attrs.y;
            if(x<=0){
                return false;
            }
        })

        const {status, title } = rect;
        let fillStyle = '#fff';
        let opacity = 0.6;
        if(status===2){
            //已出售
            fillStyle="#ffadad";
            opacity = 0.5;
            //禁止移动
            // group.draggable = false;
            // group.setAttrs({draggable : false})

        }else if(status===3){
            //锁定中
            fillStyle="#eee";
            opacity = 0.5;
            //禁止移动
            // group.draggable = false;
            // group.setAttrs({draggable : false})
        }
        //画矩形
        var box = new Konva.Rect({
            x: 0, y: 0,
            width: rect.width*radio,
            height: rect.height*radio,
            fill: fillStyle,
            stroke: '#666',
            strokeWidth: 0.5,
            opacity:opacity
        });
        group.add(box);


        let titleText,areaText;
        
        // 画展位号
        titleText = new Text({
            x:2, y:2,
            width: vWidth,
            text:title,
            fill:'#666', fontSize:12
        })
        if(rect.width===3000){
            titleText.fontSize(10);
        }
        // 画尺寸
        areaText = new Text({
            x:2, y:vHeight-12,
            width: vWidth-4,
            // text:`${Math.floor(rect.width/1000)}×${Math.floor(rect.height/1000)}=${Math.floor(rect.width*rect.height/1000000)}`,
            text:`${rect.width/1000}×${rect.height/1000}=${rect.width*rect.height/1000000}`,
            fill:'#666',fontSize:12,
            align:'right', verticalAlign:'middle'
        })
        
    
        // rect.width>3000 && group.add(titleText);
        group.add(titleText);

        rect.width>3000 && group.add(areaText);
        //画展位标尺
        // rulerIsShow.value && drawRuler(rect,width,height,group);


        //画操作相关功能，如查看详情，购买展位等
        let imageObj = new Image();
        let delicon;
        imageObj.onload = function() {
            delicon = new Konva.Image({
                x: rect.width*radio/2-13,
                y: rect.height*radio/2-13,
                image: imageObj,
                width: 26,
                height: 26,
                opacity:0.1,
                name:'icon'+rect.status,
                id:"icon"+rect.id
            });
            rect.status===1 && group.add(delicon);
            if(rect.width===3000||rect.height===3000){
                delicon.setAttrs({width:18,height:18,x: rect.width*radio/2-9, y: rect.height*radio/2-9})
            }
            //删除展位
            delicon.on('click' , (e)=>{
                console.log(e);
                Modal.confirm({
                    title: '删除展位?',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: "展位删除不可恢复，请确认！",
                    okText: '删除',
                    cancelText:"取消",
                    onOk:() => {
                        console.log(rect);
                        removeRoom({token:localStorage.getItem('mtoken'), category_id:rect.category_id, showroom_id:rect.id}).then(res=>{
                            store.dispatch("getLists",selectCategory.value.id);
                            e.target.findAncestors('Group')[0].remove();
                            layer.batchDraw();
                        });
                    }
                  });

            })
            delicon.on('mousemove', function () {
                document.body.style.cursor = 'pointer';
            });
        };
        if(rect.status===1){
            imageObj.src = require('@/assets/icon/del.png');
        }
        
        layer.add(group);
    })

    // 添加键盘事件
    // usekeyBoard(stage,layer,radio);



    //添加键盘事件  该方法已经移动到diy-use.js中
    // var container = stage.container();
    // container.tabIndex = 1;
    // container.focus();
    // const DELTA = guidWidth*radio;
    // container.addEventListener('keydown', function(e) {
    //     console.log(e.keyCode);
    //     if (e.keyCode === 37 || e.keyCode ===65) {
    //         (selectGrout.x() - DELTA)>0 && selectGrout.x(selectGrout.x() - DELTA);
    //     } else if (e.keyCode === 38 || e.keyCode ===87) {
    //         (selectGrout.y() - DELTA)>0 && selectGrout.y(selectGrout.y() - DELTA);
    //     } else if (e.keyCode === 39 || e.keyCode === 68) {
    //         selectGrout.x(selectGrout.x() + DELTA);
    //     } else if (e.keyCode === 40 || e.keyCode === 83) {
    //         selectGrout.y(selectGrout.y() + DELTA);
    //     }else if (e.keyCode === 46) {
    //         //删除
    //     } else {
    //       return;
    //     }
    //     e.preventDefault();
    //     layer.batchDraw();
    // });
   
    return layer
}