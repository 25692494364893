import { ref, reactive, toRaw, computed} from 'vue'

import {Stage, Layer, Group, Text} from 'konva';
// import {drawRuler} from './ruler.js'
import {usekeyBoard, useSetDragAlign} from '@/use/diy-use.js'
import store from '@/store/';

export let tempRectLayer = null;

export const drawTempRect = {
    init:(width=localStorage.getItem('canvasWidth')*1,height=localStorage.getItem('canvasHeight')*1,stage)=>{
        console.log("drawTempRect");

        // const rulerIsShow = computed(() => store.state.rulerIsShow);
        tempRectLayer = new Layer();

        stage.find('.temprect').each((tr)=>{
            tr.destroy();
            stage.batchDraw();
        })

        drawTempRect.draw(tempRectLayer,stage);
        stage.add(tempRectLayer)

        //键盘事件  未完成
        const selectCategory = computed(() => store.state.selectCategory);
        const radio = width/selectCategory.value.width;
        usekeyBoard(stage,tempRectLayer,radio);
    },
    draw:(layer=tempRectLayer,stage)=>{
        let width=localStorage.getItem('canvasWidth')*1;
        let height=localStorage.getItem('canvasHeight')*1;

        const selectCategory = computed(() => store.state.selectCategory);
        const lists = computed(() => store.state.drawTempLists);
 
        const radio = width/selectCategory.value.width;
        let selectGrout = null;

        // console.log("绘制临时展位");
        lists.value.map((rect)=>{
            var tween;
            let addIcon = null;
            let delIcon = null;
            let qieIcon = null;

            //矩形在页面显示的宽高
            const vWidth = rect.width*radio;
            const vHeight = rect.height*radio;

            //创建一个分组
            let group = new Group({
                x: rect.left*radio+0.5,
                y: rect.top*radio+0.5,
                width: vWidth,
                height: vHeight,
                draggable: true,
                // name:'status'+rect.status,
                name:'temprect',
                dragBoundFunc:(pos)=>{
                    let x = pos.x;
                    let y = pos.y;
                    if(x<=0){
                        x =0;
                    }
                    if(x>=width-group.attrs.width){
                        x =width-group.attrs.width;
                    }
                    if(y<=0){
                        y =0;
                    }
                    if(y>=height-group.attrs.height){
                        y =height-group.attrs.height;
                    }
                    return {
                        x:x,
                        y:y
                    }
                }
            });

            //设置当前选中的矩形
            store.commit("setSelectGroup",group);

            group.on('mousedown', function () {
                selectGrout = group;
                store.commit("setSelectGroup",selectGrout);
            });
            group.on('mouseover', function () {
                // document.body.style.cursor = 'move';
                if (tween) {tween.destroy();}
                const selectIcon= layer.find("#icon"+rect.id)[0];
                if(selectIcon){
                    tween = new Konva.Tween({
                        node: selectIcon,
                        duration: 0.3,
                        opacity:0.7,
                    }).play();
                }
            });
            group.on('mouseout', function () {
                // console.log("tempRect mouseout");
                document.body.style.cursor = 'default';
                if (tween) {tween.destroy();}
                const selectIcon= layer.find("#icon"+rect.id)[0];
                if(selectIcon){
                    selectIcon.opacity(0.1);
                    layer.draw();
                }
            });
            group.on('dragend', function(e) {
                useSetDragAlign(e.target, radio);
                let sg = e.target;
                let newRect = {width:Math.round(sg.attrs.width/radio), height: Math.round(sg.attrs.height/radio), left:Math.round(sg.attrs.x/radio), top: Math.round(sg.attrs.y/radio)};
                // console.log(rect)
                // console.log(newRect)
                // console.log(sg)
                store.commit("setTempListsUpdate",{old:rect,new:newRect});
                rect = newRect;
            });
            group.on('dragmove',(e)=>{
                let x = e.target.attrs.x;
                let y = e.target.attrs.y;
                if(x<=0){
                    return false;
                }
            })

         
            let fillStyle = '#fff';
            let opacity = 0.7;
            
            //画矩形
            var box = new Konva.Rect({
                x: 0, y: 0,
                width: rect.width*radio-0.5,
                height: rect.height*radio-0.5,
                stroke: '#666',
                fill:'#1890ff11',
                strokeWidth: 0.5,
                opacity:1
            });

            //横向尺寸
            let xSize = new Text({
                name:'xSize',
                x:0, y:4, width:rect.width*radio,
                // text:Math.floor(rect.width/1000).toFixed(1)+' m',
                text:(rect.width/1000).toFixed(1)+' m',
                fill:'#666',fontSize:12,
                align:'center', verticalAlign:'top'
            })
            //纵向尺寸
            let ySize = new Text({
                name:'ySize',
                x:16, y:0, width:rect.height*radio,
                // text:Math.floor(rect.height/1000).toFixed(1)+' m',
                text:(rect.height/1000).toFixed(1)+' m',
                fill:'#666',fontSize:12,
                align:'center', verticalAlign:'top',
                rotation:90
            })
        
            

            let areaText;
            
            // 画面积
            areaText = new Text({
                x:2, y:vHeight-14,
                width: vWidth-4,
                // text:`${Math.floor(rect.width/1000)}×${Math.floor(rect.height/1000)}=${Math.floor(rect.width*rect.height/1000000)}`,
                // text:`${rect.width/1000}×${rect.height/1000}=${rect.width*rect.height/1000000}`,
                text:`${rect.width*rect.height/1000000+'m²'}`,
                fill:'#666',fontSize:12,
                align:'right', verticalAlign:'middle'
            })

            group.add(box);
            rect.width>4000 && group.add(xSize);
            rect.width>4000 && group.add(ySize);
            rect.width>6000 && group.add(areaText);
            //画展位标尺
            // rulerIsShow.value && drawRuler(rect,width,height,group);

            //画操作相关功能，如查看详情，购买展位等
            let iconWidth = rect.width>5000 ? 20 : 15;  //操作按钮宽度
            let iconM = rect.width>5000 ? 10 : 2;  //操作按钮间距
            let addImg = new Image();
            addImg.onload = function() {
                addIcon = new Konva.Image({
                    x: 28,
                    y: 5,
                    image: addImg,
                    width: iconWidth,
                    height: iconWidth,
                    opacity:0.4
                });
                group.add(addIcon);
                addIcon.setAttrs({x:(rect.width*radio-(iconWidth*2+iconM))/2,y:(rect.height*radio-iconWidth)/2,opacity:.4});
                addIcon.on('click' , function(e){
                    //新增展位
                    store.commit("setAddPopupIsShow",true);
                    // console.log(box);
                    // let sg = e.target.findAncestors('Group')[0]
                    // 通过canvas中图形的像素大小算出的展位尺寸，和绘制中的有偏差
                    // let newRect = {width:Math.round(sg.attrs.width/radio), height: Math.round(sg.attrs.height/radio), left:Math.round(sg.attrs.x/radio), top: Math.round(sg.attrs.y/radio)};
                    // console.log(newRect);
                    // console.log(rect);
                    store.commit("setAddPopupData",rect);
                    store.commit("setSelectGroup",group);
                })
                addIcon.on('mouseover', function (e) {
                    document.body.style.cursor = 'pointer';
                    addIcon.setAttrs({opacity:.8});
                    // e.target.findAncestors('Group')[0].remove();
                });
                addIcon.on('mouseout', function () {
                    document.body.style.cursor = 'default';
                    addIcon.setAttrs({opacity:.4});
                });
            };
            addImg.src = require('@/assets/icon/add.png');
            let delImg = new Image();
            delImg.onload = function() {
                delIcon = new Konva.Image({
                    x: 68,
                    y: 5,
                    image: delImg,
                    width: iconWidth,
                    height: iconWidth,
                    opacity:0.4
                });
                group.add(delIcon);
                layer.batchDraw();

                // console.log((rect.width*radio-(iconWidth*2+iconM))/2+iconWidth+iconM,(rect.height*radio-iconWidth)/2);

                delIcon.setAttrs({x:(rect.width*radio-(iconWidth*2+iconM))/2+iconWidth+iconM,y:(rect.height*radio-iconWidth)/2,opacity:.4});
                delIcon.on('click' , (e)=>{
                    console.log("del");
                    store.commit("setTempListsRemove",rect);
                    e.target.findAncestors('Group')[0].remove();
                })
                delIcon.on('mouseover', function () {
                    document.body.style.cursor = 'pointer';
                    delIcon.setAttrs({opacity:.8});
                });
                delIcon.on('mouseout', function () {
                    document.body.style.cursor = 'default';
                    delIcon.setAttrs({opacity:0.4});
                });
            };
            delImg.src = require('@/assets/icon/del.png');

            //画切片按钮
            if(rect.width>=9000||rect.height>=9000){
                let qieImg = new Image();
                qieImg.onload = function() {
                    qieIcon = new Konva.Image({
                        x: 68,
                        y: 5,
                        image: qieImg,
                        width: iconWidth,
                        height: iconWidth,
                        opacity:0.4
                    });
                    group.add(qieIcon);
                    layer.batchDraw();

                    if((rect.height>=9000&&rect.width<=6000)||(rect.width<rect.height)){
                        // console.log("按钮纵向排列")
                        //按钮纵向排列
                        addIcon.setAttrs({x:(rect.width*radio - iconWidth)/2,y:(rect.height*radio - 80)/2});
                        delIcon.setAttrs({x:(rect.width*radio - iconWidth)/2,y:(rect.height*radio - 80)/2+30});
                        qieIcon.setAttrs({x:(rect.width*radio - iconWidth)/2,y:(rect.height*radio - 80)/2+60,opacity:.4})
                    }else{
                        //按钮横向排列
                        // console.log("按钮横向排列")
                        addIcon.setAttrs({x:(rect.width*radio - 80)/2});
                        delIcon.setAttrs({x:(rect.width*radio - 80)/2+30});
                        qieIcon.setAttrs({x:(rect.width*radio - 80)/2+60,y:(rect.height*radio-iconWidth)/2,opacity:.4})
                    }
                    layer.batchDraw();
                    qieIcon.on('click' , (e)=>{
                        console.log("crop");
                        store.commit("setCropPopupIsShow",true);
                        store.commit("setCropPopupData",rect);
                        store.commit("setSelectGroup",group);
                        // e.target.findAncestors('Group')[0].remove();
                        // store.commit("setTempListsRemove",rect);
                    })
                    qieIcon.on('mouseover', function () {
                        document.body.style.cursor = 'pointer';
                        qieIcon.setAttrs({opacity:.8});
                    });
                    qieIcon.on('mouseout', function () {
                        document.body.style.cursor = 'default';
                        qieIcon.setAttrs({opacity:0.4});
                    });
                };
                qieImg.src = require('@/assets/icon/qiepian.png');
            }

            layer.add(group);

        })
    },
    update:()=>{
        // console.log("update")
        drawTempRect.draw(tempRectLayer);
    },
    removeAll:()=>{
        tempRectLayer.destroyChildren();
    }
}

// export function drawTempRect(width=localStorage.getItem('canvasWidth')*1,height=localStorage.getItem('canvasHeight')*1,stage) {

//     console.log("drawTempRect");

//     // const rulerIsShow = computed(() => store.state.rulerIsShow);
//     const selectCategory = computed(() => store.state.selectCategory);
//     const lists = computed(() => store.state.tempLists);
//     const layer = new Layer();
//     const radio = width/selectCategory.value.width;
//     let selectGrout = null;

//     var tween;
//     let addIcon = null;
//     let delIcon = null;
//     let qieIcon = null;


//     stage.find('.temprect').each((tr)=>{
//         tr.destroy();
//         stage.batchDraw();
//     })

//     lists.value.map((rect)=>{

//         //矩形在页面显示的宽高
//         const vWidth = rect.width*radio;
//         const vHeight = rect.height*radio;

//         console.log("绘制临时展位");
//         // console.log(vWidth,vHeight)
        
//         //创建一个分组
//         let group = new Group({
//             x: rect.left*radio+0.5,
//             y: rect.top*radio+0.5,
//             width: vWidth,
//             height: vHeight,
//             draggable: true,
//             // name:'status'+rect.status,
//             name:'temprect',
//             dragBoundFunc:(pos)=>{
//                 let x = pos.x;
//                 let y = pos.y;
//                 if(x<=0){
//                     x =0;
//                 }
//                 if(x>=width-group.attrs.width){
//                     x =width-group.attrs.width;
//                 }
//                 if(y<=0){
//                     y =0;
//                 }
//                 if(y>=height-group.attrs.height){
//                     y =height-group.attrs.height;
//                 }
//                 return {
//                     x:x,
//                     y:y
//                 }
//             }
//         });


//         group.on('mousedown', function () {
//             selectGrout = group;
//             store.commit("setSelectGroup",selectGrout);
//         });
//         group.on('mouseover', function () {
//             // document.body.style.cursor = 'move';
//             if (tween) {tween.destroy();}
//             const selectIcon= layer.find("#icon"+rect.id)[0];
//             if(selectIcon){
//                 tween = new Konva.Tween({
//                     node: selectIcon,
//                     duration: 0.3,
//                     opacity:0.7,
//                 }).play();
//             }
//         });
//         group.on('mouseout', function () {
//             // console.log("tempRect mouseout");
//             document.body.style.cursor = 'default';
//             if (tween) {tween.destroy();}
//             const selectIcon= layer.find("#icon"+rect.id)[0];
//             if(selectIcon){
//                 selectIcon.opacity(0.1);
//                 layer.draw();
//             }
//         });
//         group.on('dragend', function(e) {
//             useSetDragAlign(e.target, radio);
//             let sg = e.target;
//             let newRect = {width:Math.round(sg.attrs.width/radio), height: Math.round(sg.attrs.height/radio), left:Math.round(sg.attrs.x/radio), top: Math.round(sg.attrs.y/radio)};
//             store.commit("setTempListsUpdate",{old:rect,new:newRect});
//         });
//         group.on('dragmove',(e)=>{
//             let x = e.target.attrs.x;
//             let y = e.target.attrs.y;
//             if(x<=0){
//                 return false;
//             }
//         })

       
//         let fillStyle = '#fff';
//         let opacity = 0.7;
        
//         //画矩形
//         var box = new Konva.Rect({
//             x: 0, y: 0,
//             width: rect.width*radio-0.5,
//             height: rect.height*radio-0.5,
//             stroke: '#666',
//             fill:'#1890ff11',
//             strokeWidth: 0.5,
//             opacity:1
//         });

//         //横向尺寸
//         let xSize = new Text({
//             name:'xSize',
//             x:0, y:4, width:rect.width*radio,
//             // text:Math.floor(rect.width/1000).toFixed(1)+' m',
//             text:(rect.width/1000).toFixed(1)+' m',
//             fill:'#666',fontSize:12,
//             align:'center', verticalAlign:'top'
//         })
//         //纵向尺寸
//         let ySize = new Text({
//             name:'ySize',
//             x:16, y:0, width:rect.height*radio,
//             // text:Math.floor(rect.height/1000).toFixed(1)+' m',
//             text:(rect.height/1000).toFixed(1)+' m',
//             fill:'#666',fontSize:12,
//             align:'center', verticalAlign:'top',
//             rotation:90
//         })
       
        

//         let areaText;
        
//         // 画面积
//         areaText = new Text({
//             x:2, y:vHeight-14,
//             width: vWidth-4,
//             // text:`${Math.floor(rect.width/1000)}×${Math.floor(rect.height/1000)}=${Math.floor(rect.width*rect.height/1000000)}`,
//             // text:`${rect.width/1000}×${rect.height/1000}=${rect.width*rect.height/1000000}`,
//             text:`${rect.width*rect.height/1000000+'m²'}`,
//             fill:'#666',fontSize:12,
//             align:'right', verticalAlign:'middle'
//         })

//         group.add(box);
//         rect.width>4000 && group.add(xSize);
//         rect.width>4000 && group.add(ySize);
//         rect.width>6000 && group.add(areaText);
//         //画展位标尺
//         // rulerIsShow.value && drawRuler(rect,width,height,group);

//         //画操作相关功能，如查看详情，购买展位等
//         let iconWidth = rect.width>5000 ? 20 : 15;  //操作按钮宽度
//         let iconM = rect.width>5000 ? 10 : 2;  //操作按钮间距
//         let addImg = new Image();
//         addImg.onload = function() {
//             addIcon = new Konva.Image({
//                 x: 28,
//                 y: 5,
//                 image: addImg,
//                 width: iconWidth,
//                 height: iconWidth,
//                 opacity:0.4
//             });
//             group.add(addIcon);
//             addIcon.setAttrs({x:(rect.width*radio-(iconWidth*2+iconM))/2,y:(rect.height*radio-iconWidth)/2,opacity:.4});
//             addIcon.on('click' , function(e){
//                 //新增展位
//                 store.commit("setAddPopupIsShow",true);
//                 console.log(box);
//                 // let sg = e.target.findAncestors('Group')[0]
//                 // let newRect = {width:Math.round(sg.attrs.width/radio), height: Math.round(sg.attrs.height/radio), left:Math.round(sg.attrs.x/radio), top: Math.round(sg.attrs.y/radio)};
//                 // console.log(newRect);
//                 console.log(rect);
//                 store.commit("setAddPopupData",rect);
//             })
//             addIcon.on('mouseover', function () {
//                 document.body.style.cursor = 'pointer';
//                 addIcon.setAttrs({opacity:.8});
//             });
//             addIcon.on('mouseout', function () {
//                 document.body.style.cursor = 'default';
//                 addIcon.setAttrs({opacity:.4});
//             });
//         };
//         addImg.src = require('@/assets/icon/add.png');
//         let delImg = new Image();
//         delImg.onload = function() {
//             delIcon = new Konva.Image({
//                 x: 68,
//                 y: 5,
//                 image: delImg,
//                 width: iconWidth,
//                 height: iconWidth,
//                 opacity:0.4
//             });
//             group.add(delIcon);
//             layer.batchDraw();

//             delIcon.setAttrs({x:(rect.width*radio-(iconWidth*2+iconM))/2+iconWidth+iconM,y:(rect.height*radio-iconWidth)/2,opacity:.4});
//             delIcon.on('click' , (e)=>{
//                 console.log("del");
//                 store.commit("setTempListsRemove",rect);
//             })
//             delIcon.on('mouseover', function () {
//                 document.body.style.cursor = 'pointer';
//                 delIcon.setAttrs({opacity:.8});
//             });
//             delIcon.on('mouseout', function () {
//                 document.body.style.cursor = 'default';
//                 delIcon.setAttrs({opacity:0.4});
//             });
//         };
//         delImg.src = require('@/assets/icon/del.png');

//         //画切片按钮
//         if(rect.width>=9000||rect.height>=9000){
//             let qieImg = new Image();
//             qieImg.onload = function() {
//                 qieIcon = new Konva.Image({
//                     x: 68,
//                     y: 5,
//                     image: qieImg,
//                     width: iconWidth,
//                     height: iconWidth,
//                     opacity:0.4
//                 });
//                 group.add(qieIcon);
//                 layer.batchDraw();
//                 if(rect.height>=9000&&rect.width<=6000){
//                     //按钮纵向排列
//                     addIcon.setAttrs({x:(rect.width*radio - iconWidth)/2,y:(rect.height*radio - 80)/2});
//                     delIcon.setAttrs({x:(rect.width*radio - iconWidth)/2,y:(rect.height*radio - 80)/2+30});
//                     qieIcon.setAttrs({x:(rect.width*radio - iconWidth)/2,y:(rect.height*radio - 80)/2+60,opacity:.4})
//                 }else{
//                     //按钮横向排列
//                     addIcon.setAttrs({x:(rect.width*radio - 80)/2});
//                     delIcon.setAttrs({x:(rect.width*radio - 80)/2+30});
//                     qieIcon.setAttrs({x:(rect.width*radio - 80)/2+60,y:(rect.height*radio-iconWidth)/2,opacity:.4})
//                 }
//                 qieIcon.on('click' , (e)=>{
//                     console.log("crop");
//                     store.commit("setCropPopupIsShow",true);
//                     store.commit("setCropPopupData",rect);
//                     // store.commit("setTempListsRemove",rect);
//                 })
//                 qieIcon.on('mouseover', function () {
//                     document.body.style.cursor = 'pointer';
//                     qieIcon.setAttrs({opacity:.8});
//                 });
//                 qieIcon.on('mouseout', function () {
//                     document.body.style.cursor = 'default';
//                     qieIcon.setAttrs({opacity:0.4});
//                 });
//             };
//             qieImg.src = require('@/assets/icon/qiepian.png');
//         }

//         layer.add(group);
//     })

//     usekeyBoard(stage,layer,radio);

//     return layer
// }