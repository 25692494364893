<template>
  <a-modal :zIndex="9" title="DIY展位" v-model:visible="show" width="100%" @cancel="close" :destroyOnClose="false" wrapClassName="full-modal" :footer="null">
      <div class="headbox">
        <!-- <div class="tips">
          <div class="normal">未出售</div>
          <div class="lock">锁定中</div>
          <div class="finish">已出售</div>
          <div class="choice">当前绘制</div>
        </div> -->
        <div class="toolbox">
          <div @click="changeGuidWidth(500)" :class="{'che':guidWidth==500}" class="jingdu half">0.5</div>
          <div @click="changeGuidWidth(1000)" :class="{'che':guidWidth==1000}" class="jingdu one">1m</div>

          <!-- <div @click="changeToolType('minus')" class="tool minus" :class="{'che':toolType==='minus'}" title="删除选区">-</div>  -->
          <!-- <div @click="changeToolType('add')" class="tool add" :class="{'che':toolType==='add'}" title="增加选区">+</div>  -->

          <!-- <div class="item">
            <span style="margin-right:4px;">网格</span>
            <a-switch
                size="small"
                @change="gridSwitch"
                v-model:checked="gridIsShow"
                active-color="#247fce"
                inactive-color="#ccc">
            </a-switch>
          </div> -->
        </div>
        <div class="btnbox">
          <a-button type="primary" @click="batchUpdate()" :loading="confirmLoading" v-if="tempList.length>0">
            <CloudUploadOutlined  v-if="!confirmLoading" />批量上传
          </a-button>
          <a-button danger @click="removeAllRect()" v-if="listEditable.length>0">
            <DeleteOutlined v-if="!confirmLoading" />删除全部展位
          </a-button>
          <a-button danger @click="removeTempRect()" v-if="tempList.length>0">
            <ClearOutlined v-if="!confirmLoading" />清空临时展位
          </a-button>
        </div>
      </div>
     
      <div class="clip-content">
		    <div id="editCanvas" :style="mapStyle.outer"></div>
      </div>

      <Loading :visible="loadingBoxVisible" :loadingIndex="loadingIndex" :loadingSum="loadingSum"></Loading>
      
    </a-modal>
</template>

<script>
import { ExclamationCircleOutlined,DeleteOutlined,CloudUploadOutlined,ClearOutlined } from '@ant-design/icons-vue';
import { createVNode, ref, toRefs, watch, reactive,computed, onMounted } from 'vue'
import Konva from 'konva';
// import {Stage, Layer, Circle} from 'konva';
import {useStore} from 'vuex'
import {useGetMapStyle2} from '@/use/diy-use.js'
import {drawCanvas,stage} from './index.js'
import { Modal } from 'ant-design-vue';
import {createRoom,removeRoom} from "@/api/index.js";
import Loading from "@/components/Loading.vue";

  export default {
    components:{Loading,DeleteOutlined, CloudUploadOutlined,ClearOutlined},
    props:{
      datas:{type:Object,default:()=>{}},
      editVisible:{type:Boolean, default:false}
    },
    setup(props,{emit}){
      // const rectWidth = ref(1000);
      const show = ref(false);
      const store = useStore();
      const selectCategory = computed(() => store.state.selectCategory); //展馆信息
      const tempList = computed(() => store.state.tempLists);
      const lists = computed(() => store.state.lists);
      const listEditable = computed(() => store.state.lists.filter(item=>item.status===1));
      const mapStyle =ref({});
      const confirmLoading = ref(false);

      const guidWidth = computed(()=>store.state.guidWidth);

      const canvasWidth = ref(1500);
      const canvasHeight = ref(936);

      const gridIsShow = ref(true); //显示网格

      //弹出层进度条组件
      const loadingBoxVisible = ref(false);
      const loadingSum = ref(100);
      const loadingIndex = ref(0);


      show.value = props.editVisible;
      

      // onMounted(()=>{
      //   console.log('popupEdit mounted');
      // })
 
      const changeGuidWidth = (w)=>{
        store.commit("setGuidWidth",w);
        // drawCanvas();
        drawCanvas.updateGuid(canvasWidth.value,canvasHeight.value);
      }

      const gridSwitch = ()=>{

      }

      const close = ()=>{
        show.value = false;
        emit('update:editVisible', false)
      }

      //初始化
      watch(props,function(newValue, oldValue){
        console.log(newValue)
        show.value = newValue.editVisible;
        if(!newValue.editVisible){
          return false;
        }
        setTimeout(()=>{
          mapStyle.value = useGetMapStyle2(selectCategory.value);
          canvasWidth.value = mapStyle.value.canvasWidth;
	        canvasHeight.value = mapStyle.value.canvasHeight;
          console.log(mapStyle.value);
          drawCanvas.init(canvasWidth.value,canvasHeight.value);
          drawCanvas.updateTempRect();

          let beforeWidth = localStorage.getItem('canvasWidth');
          let beforeHeight = localStorage.getItem('canvasHeight');
          if(beforeWidth!=canvasWidth.value || beforeHeight!=canvasHeight.value){
              console.log("窗口尺寸有更新！");
              drawCanvas.reset(canvasWidth.value,canvasHeight.value);
          }
        },100)

      })

      // 监控数据变化
      watch(tempList,function(newValue, oldValue){
        console.log("templist数据变化")
        // props.editVisible && drawCanvas(canvasWidth.value,canvasHeight.value);
        drawCanvas.updateTempRect();
      })

      //监控数据变化
      // watch(lists,function(newValue, oldValue){
      //   console.log("lists数据变化")
      //   console.log(newValue)
      //   // drawCanvas(canvasWidth.value,canvasHeight.value);
      // })

      const batchUpdate = ()=>{
        console.log("批量上传")
        Modal.confirm({
          title: '批量上传',
          icon: createVNode(ExclamationCircleOutlined),
          content: "批量上传会随机命名展位编号，建议填写展位编号独立上传！",
          okText: '确定',
          cancelText:"取消",
          okType: 'danger',
          onOk:() => {
            // 展馆编号
            const categoryIndex = localStorage.getItem("categoryIndex")*1+1;
            let i=0;
            loadingBoxVisible.value = true;
            loadingSum.value = tempList.value.length;
            const upload = function({width,height,left,top}){
              i++;
              loadingIndex.value = i;
              createRoom({
                token:localStorage.getItem('mtoken'),
                category_id:selectCategory.value.id,
                parent_id:'0',status:'1',
                width,height,top,left,
                title:categoryIndex+""+Math.ceil(Math.random()*1000),
              }).then(res=>{
                if(tempList.value[i]){
                  upload(tempList.value[i]);
                }else{
                  store.dispatch("getLists",selectCategory.value.id).then(()=>{
                    store.commit("setTempListsRemoveAll",[]);
                    drawCanvas.removeTempRect(); //上传成功后，清空临时展位
                  });
                  loadingBoxVisible.value = false;
                  loadingIndex.value = 0;
                }
              })
            }
            upload(tempList.value[i]);
          }
        });
      }

      const removeTempRect = ()=>{
        Modal.confirm({
          title: '清空临时展位',
          icon: createVNode(ExclamationCircleOutlined),
          content: "清除操作不可逆，请确认！",
          okText: '清空',
          cancelText:"取消",
          okType: 'danger',
          onOk:() => {
            store.commit("setTempListsRemoveAll",[]);
            drawCanvas.removeTempRect(); //清空临时展位
          }
        });
      }

      const removeAllRect = ()=>{
        Modal.confirm({
          title: '删除全部展位（未出售的展位）',
          icon: createVNode(ExclamationCircleOutlined),
          content: "删除操作不可逆，请谨慎操作！",
          okText: '全部删除',
          cancelText:"取消",
          okType: 'danger',
          onOk:() => {
            let i=0;
            loadingBoxVisible.value = true;
            loadingSum.value = listEditable.value.length;
            const removeAll = function(rect){
              i++;
              loadingIndex.value = i;
              removeRoom({
                token:localStorage.getItem('mtoken'),
                category_id:rect.category_id, 
                showroom_id:rect.id
              }).then(res=>{
                if(listEditable.value[i]){
                  removeAll(listEditable.value[i]);
                }else{
                  store.dispatch("getLists",selectCategory.value.id).then(()=>{
                    drawCanvas.init(canvasWidth.value,canvasHeight.value);
                    stage.find('.cloudrect').each((group)=>{
                        console.log(group.attrs.type);
                        if(group.attrs.type==="status1"){
                          group.destroy();
                          stage.batchDraw();
                        }
                    })
                  });
                  loadingBoxVisible.value = false;
                  loadingIndex.value = 0;
                }
              })
            }
            removeAll(listEditable.value[i]);
          }
        });
      }

      return {
        mapStyle,
        changeGuidWidth,
        close,
        show,
        guidWidth,
        gridIsShow,
        tempList,
        lists,
        listEditable,
        gridSwitch,
        confirmLoading,
        batchUpdate,
        removeTempRect,
        removeAllRect,
        loadingBoxVisible,
        loadingSum,
        loadingIndex
      }
    }
  }
</script>

<style scoped>
.el-dialog .el-dialog__body{padding:10px 20px 30px 20px;}

.headbox{width:100%; display: flex; justify-content: space-between; margin-top:-20px;}
.headbox .tips{margin-bottom:10px; margin-top:20px; display:flex;}
.headbox .tips>div{padding-left:30px; position: relative; font-size:12px; line-height:14px; margin:0 5px; }
.headbox .tips>div:before{content:''; display:block; width:25px; height:12px; border:1px solid #eee; position: absolute; top:0px; left:0; border-radius: 4px;}
.headbox .tips>div.finish:before{background:#ffadad;}
.headbox .tips>div.lock:before{background:#aaa; opacity:.3;}
.headbox .tips>div.choice:before{background:rgba(36,127,206,.2);}

.headbox .toolbox{display:flex; align-items: center;}
.headbox .toolbox .tool{width:20px; height:20px; line-height:20px; margin-left:4px; border:1px dashed #666; text-align: center; cursor:pointer; font-size:15px;}
.headbox .toolbox .tool.che{background:rgba(36,127,206,.31);}
.headbox .toolbox .jingdu{position:relative; font-size:6px; border-bottom:1px dashed #ccc; width:20px; text-align:center; cursor:pointer; margin-left:5px; height:21px; line-height:28px; color:#ccc;}
.headbox .toolbox .jingdu::before{content:''; position: absolute; bottom:0; height:4px; left:0; border-left:1px solid #ccc;}
.headbox .toolbox .jingdu::after{content:''; position: absolute; bottom:0; height:4px; right:0; border-right:1px solid #ccc;}
.headbox .toolbox .jingdu.one{width: 30px;}
.headbox .toolbox .jingdu.che{color:#247fce;  border-bottom:1px dashed #247fce;}
.headbox .toolbox .jingdu.che::before{border-left:1px solid #247fce;}
.headbox .toolbox .jingdu.che::after{border-right:1px solid #247fce;}

.clip-content{border:1px dashed #ccc; margin-top:10px; display:flex; background: rgba(255,140,0,.15); padding:10px 10px 10px 10px; user-select: none;
position: absolute;left: 0px; right: 0px; top: 90px; bottom: 0px;}

#editCanvas{margin:auto; background:#fff; border:2px solid #333;}

.btnbox>button{margin:0 5px;}
</style>

<style>
  .ant-modal-header{border-bottom:none;}
  .full-modal .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .full-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
 .full-modal  .ant-modal-body {
    flex: 1;
  }
</style>