<template>
  <div>
    <a-modal :zIndex="1001"  title="展位拆分" v-model:visible="cropPopupIsShow"  @cancel="handleCancel" width="700px" :footer="null">
        <a-form :label-col="{span: 4}" :wrapper-col="{span: 18}">
            <div style="margin-bottom:15px;">当前展位尺寸: {{formState.width/1000}}米  ×  {{formState.height/1000}}米</div>
             <a-form-item label="请选择拆分尺寸">
              <a-radio-group v-model:value="radioValue">
                <a-radio :style="radioStyle" :value="1">3米 × 3米</a-radio>
                <a-radio :style="radioStyle" :value="2">
                  自定义尺寸
                  <a-input-number v-model:value="w" v-if="radioValue === 2" min="3" :max="formState.width/1000" placeholder="宽(m)" style="width: 80px; margin-left: 10px" />
                  <span v-if="radioValue === 2" style="padding:0 4px;"> × </span>
                  <a-input-number v-model:value="h" v-if="radioValue === 2" min="3" :max="formState.height/1000" placeholder="高(m)" style="width: 80px;" />
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <div class="tips"><span>温馨提示：</span>展位宽高最小为3米，不满3米的展位将会被合并！</div>


        </a-form>
        <div class="dialog-footer">
          <a-button type="primary" @click="sub()" :loading="confirmLoading"><PlusCircleOutlined v-if="!confirmLoading" />确定</a-button>
        </div>
    </a-modal>
  </div>
</template>
<script>
import { ref, reactive, defineComponent, onMounted,computed, watch, toRaw } from 'vue';
import {useStore} from 'vuex'
// import {createRoom} from "@/api/index.js";
import {PlusCircleOutlined} from '@ant-design/icons-vue';
// import {message} from 'ant-design-vue';
import {stage} from './index.js'; 


export default defineComponent({
  components:{PlusCircleOutlined},
  setup(props,{emit}) {
    const store = useStore();
    const cropPopupIsShow = computed(()=>store.state.cropPopupIsShow);
    const cropPopupData =computed(()=>store.state.cropPopupData);
    // const selectCategory = computed(()=>store.state.selectCategory);
    const selectGroup = computed(()=>store.state.selectGroup);
    const confirmLoading = ref(false);
    const radioValue = ref(1);
    const w = ref(3);
    const h = ref(3);
    const formState = reactive({
      width:'',
      height:'',
      top:'',
      left:''
    });

    const handleCancel = ()=>{
      console.log("关闭弹出层")
      store.commit("setCropPopupIsShow",false);
    }


    //兼容弹出层状态，状态变化时候更新表单内容
    watch(cropPopupIsShow, function(news,old){
      if(!news){
        return false;
      }
      console.log(cropPopupData.value);
      const rdata = cropPopupData.value;
      formState.width = rdata.width;
      formState.height = rdata.height;
      formState.left = rdata.left;
      formState.top = rdata.top;
    })

    const sub = ()=>{
      
      console.log(formState.width,formState.height,formState.left,formState.top);
      let gw,gh; // 裁剪网格的宽高
      if(radioValue.value===1){
        gw = 3000; gh = 3000;
      }else{
        gw =w.value*1000; gh = h.value*1000
      }

      console.log(gw,gh);

      let xnum = Math.floor(formState.width/gw);
      let ynum = Math.floor(formState.height/gh);
      console.log(xnum,ynum);
      const cropArr = [];
      for(let i=0; i<xnum; i++){
        for(let j=0; j<ynum; j++){
          let cropW = i<xnum-1? gw : gw + formState.width%(gw);
          let cropH = j<ynum-1? gh : gh + formState.height%(gh);
          cropArr.push({
            width:cropW, 
            height: cropH, 
            left: formState.left + i* gw,
            top: formState.top + j* gh
          })
        }
      }

      console.log(cropArr);

      confirmLoading.value=true;
      setTimeout(()=>{
        confirmLoading.value=false;
        store.commit("setCropPopupIsShow",false);
        //删除拆分之前的展位
        store.commit("setTempListsRemove",store.state.cropPopupData);
        //添加拆分后的展位数组
        store.commit("setTempLists",cropArr);
        //删除绘制的临时展位
        let sg = toRaw(selectGroup.value);
        sg.remove();
        stage.batchDraw();
      },500)


      
      // confirmLoading.value=true;
      // console.log('values', formState, toRaw(formState));
      
      // setTimeout(()=>{
      //   confirmLoading.value=false;
      //   formRef.value.resetFields();
      //   store.commit("setCropPopupIsShow",false);
      //   store.commit("setTempListsRemove",store.state.cropPopupData);
      // },1000)
        
    }

   const radioStyle = reactive({
      display: 'block',
      height: '35px',
      lineHeight: '35px',
    });

    return {
      cropPopupIsShow,
      confirmLoading,
      handleCancel,
      other: '',
      formState,
      radioValue,radioStyle,w,h,
      sub
    };
  },
});
</script>

<style scoped>
.dialog-footer{text-align: right;}
.dialog-footer button{margin-left:10px;}

.tips{margin-top:20px; color:red;}
</style>